<template>
  <div class="class-list">
    <GlobalInfoBar
      title="门店商家二维码"
      content="说明："
    />
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <MerchantsQrcodeForm />
      </div>

      <MerchantsQrcodeTable />
    </GlobalChunk>
  </div>
</template>

<script>
import MerchantsQrcodeForm from "./Form";
import MerchantsQrcodeTable from "./Table";
export default {
  name: "class-list",
  components: { MerchantsQrcodeForm, MerchantsQrcodeTable },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
</style>