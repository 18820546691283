<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="this.seachDataList"
      :currentPage="page.pageNum"
      :total="this.page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="历史分配次数" slot="historyCount" align="center">
        <template slot-scope="{ row }">
          <el-button
            v-if="row.historyCount"
            type="text"
            class="text-btn"
            @click="handleShowTimes(row)"
            >{{ row.historyCount }}</el-button
          >
          <span v-else>0</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" @click="handleBtn(row)"
            >分配激活</el-button
          >
          <el-button size="mini" type="primary" @click="handleBtn(row)"
            >修改备注</el-button
          >
        </template>
      </el-table-column>
    </GlobalTable>

    <!--弹框-->
    <el-dialog
      title="查看历史分配"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="800px"
      @closed="handleDialogClosed"
    >
      <div>
        <span style="margin-right:20px">合计分配总量: <span class="history-color">{{historyData.total}}</span></span>
        <span>合计分配次数: <span class="history-color">{{historyData.count}}</span></span>
      </div>
      <GlobalTable
        ref="GlobalTable"
        class="frequency-table"
        v-loading="frequencyLoading"
        :maxHeight="500"
        :columns="frequencyColumns"
        :data="this.historyDataList"
        :isPagination="false"
      >
      </GlobalTable>
    </el-dialog>
  </div>
</template>

<script>
import MinXinRequest from "@/mixins/request";
import _api from "@/utils/request";
import Moment from 'moment'
export default {
  name: "Table",
  mixins: [MinXinRequest],
  data() {
    return {
      loading: false,
      frequencyLoading: false,
      seachDataList: [],
      historyDataList: [],
      historyData:{},
      ResidentEnable: {
        id: "",
        isEnable: "",
      },
      companyId: "",
      pageNum: 1,
      //弹窗
      dialogVisible: false, // 弹框的出现与否
      dialogTitle: "", // 标题
      dialogTip: "", // 弹窗提示内容
      tableColumns: [
        { label: "商家名称", prop: "companyName" },
        { label: "省市区", prop: "fullAddress" },
        { label: "地址", prop: "address" },
        { label: "联系电话", prop: "phone" },
        { label: "门店数量", prop: "storeNum" },
        { label: "历史分配二维码", prop: "historyCode" },
        { slotName: "historyCount" },
        { label: "当前总剩余二维码", prop: "totalSurplusNum" },
        { label: "当前门店平均剩余", prop: "avgSurplusNum" },
        { label: "最近分配时间", prop: "latestTime" },
        { label: "二维码使用率", prop: "usageRate" },
      ],
      frequencyColumns:[
        { label: "批次号", prop: "batchCode" },
        { label: "本批次分配二维码", prop: "historyCodeExpress" },
        { label: "本批次总剩余二维码", prop: "countTotalSurplusNum" },
        { label: "本批次门店平均剩余", prop: "avgSurplusNum" },
        { label: "本批次分配时间", prop: "createTime" },
      ]
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    seachTotal() {
      return this.$store.state.tagsView.seachTotal;
    },
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      // this.pageNum = 1;
      this.handleCurrentChange();
    },
  },
  methods: {
    // 表格切换页面
    handleCurrentChange(val) {
      this.loading = true
      if (val) {
        this.pageNum = val;
        this.page.pageNum = val;
      } else {
        this.page.pageNum = 1;
      }
      if(this.SeachParams.sort){
        this.page.pageNum = this.pageNum
      }else{
        this.pageNum = 1
      }
      const baseRequest = {
        companyName: this.SeachParams.companyName,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.$emit("handleCurrentChange", val);
      _api.barCodeHistoryList(baseRequest).then((res) => {
        if (res.code === 1) {
          let merchantLists = res.data.records
          if(this.SeachParams.sort){
            merchantLists.forEach(item => {
            if(item.latestTime){
                item.latestTime = Date.parse(item.latestTime);
              }
            })
            if(this.SeachParams.sort == 'asc'){
              merchantLists.sort(this.compare('latestTime',true))
            }else{
              merchantLists.sort(this.compare('latestTime',false))
            }
            merchantLists.forEach(item => {
              if(item.latestTime){
                item.latestTime = Moment(item.latestTime).format('YYYY-MM-DD HH:mm:ss')
              }
            })
          }
          this.seachDataList = res.data.records;
          this.page.pageNum = res.data.current;
          this.page.total = res.data.total;
          this.$store.commit("tagsView/POST_TOTAL", res.data.total);
          this.$store.commit("tagsView/POST_RESIDENT", this.seachDataList);
          this.loading = false
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
            this.SeachParams.disuseLoding();
          }
      });
    },
    // 表格分配条码操作
    handleBtn(row) {
      console.log("分配条码");
    },
    //弹窗取消
    handleClose() {
      this.dialogVisible = false;
    },
    //弹窗确定
    handleSuccess() {
      _api.classEnable(this.ResidentEnable).then((res) => {
        if (res.code === 1) {
          this.$message.success("操作成功");
          this.dialogVisible = false;
        }
      });
    },
    handleDialogClosed() {
      this.dialogVisible = false;
    },
    barCodeHistory(params) {
      this.frequencyLoading = true
      _api.barCodeHistory(params).then(res => {
        if(res.code === 1){
          this.historyDataList = res.data.list
          this.historyData.count = res.data.count
          this.historyData.total = res.data.total
          this.frequencyLoading = false
        }
      })
    },
    handleShowTimes(row) {
      this.dialogVisible = true
      let params = {
        companyId:row.companyId
      }
      this.barCodeHistory(params)
    },
    // 排序
    compare(property,data){
      return function(a,b){
          var value1 = a[property];
          var value2 = b[property];
          if(data){
            return value2 - value1;
          }else{
            return value1 - value2;
          }
      }
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .text-btn {
    margin-bottom: 0 !important;
    text-decoration: underline;
  }
  .frequency-table{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .history-color{
    color: #f83232;
  }
}
</style>
