<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <div slot="AddSlot">
        <el-radio-group
          class="selectSort"
          v-model="filterFormData.sortType"
          @change="changeSort"
        >
          <el-radio label="time">
            最近分配时间排序
            <j-sort
              @handleSort="handleSort"
              style="margin-left: 10px"
              :sortActive="selectSortData.timeSort"
            ></j-sort>
          </el-radio>
        </el-radio-group>
      </div>
    </GlobalForm>
  </div>
</template>

<script>
import { formValidateMobile } from "@/utils/form-validate";
import JSort from "@/components/sort/jSort";
import moment from 'moment'
export default {
  name: "Form",
  components: { JSort },
  data() {
    return {
      initData: {
        entryTime: [moment().day(moment().day()).format('YYYY-MM-DD 00:00:00'),moment().format('YYYY-MM-DD 23:59:59')]
      },
      formData: {},
      filterFormData: {
        sortType: "time",
      },
      selectSortData: {
        timeSort: "",
      },
      formItemList: [
        {
          key: "companyName",
          type: "input",
          labelName: "商家名称",
          placeholder: "输入商家名称查询",
        },
        {
          key: "entryTime",
          type: "pickerOptions",
          labelName: "分配时间",
          placeholder: "请选择分配时间",
          valueFormat: "timestamp",
          clearable: true,
        },
        { slot: "AddSlot" },
      ],
      formRules: {
        mobile: [
          {
            validator: formValidateMobile,
            trigger: "blur",
          },
        ],
      },
    };
  },
  created(){
    this.handleConfirm(this.initData)
  },
  methods: {
    handleConfirm(data,cd) {
      console.log("提交了form", data);
      let SeachParams = {}
      if(data){
        this.formData = data
        SeachParams = {
          companyName: data.companyName,
          startTime: data.entryTime? moment(data.entryTime[0]).format("x"):"",
          endTime: data.entryTime? moment(data.entryTime[1]).format("x"):"",
          pageNum: 1,
          pageSize: 10,
          disuseLoding: () => {
            if (typeof cd === 'function') {
              cd()
            }
          },
        };
      }
      this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
    },
    handleSort(item) {
      if (this.filterFormData.sortType === "time") {
        this.selectSortData.timeSort = item;
        this.filterFormData.sort = this.selectSortData.timeSort;
      }
      const SeachParams = {
        companyName: this.formData.companyName,
        startTime: this.formData.entryTime? moment(this.formData.entryTime[0]).format("x"):"",
        endTime: this.formData.entryTime? moment(this.formData.entryTime[1]).format("x"):"",
        sort: this.filterFormData.sort
      };
      this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
    },
    changeSort(e) {
      if (e === "time") {
        this.selectSortData.timeSort = "desc";
        this.filterFormData.sort = this.selectSortData.timeSort;
      }
      this.handleSort();
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
  .selectSort {
    display: flex;
    .el-radio {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      /deep/.el-radio__label {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
